<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">{{ activeMenu.modulo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import useTemplate from "../../template/composables/useTemplate";
export default {
  setup() {
    const { activeMenu } = useTemplate();
    return { activeMenu };
  },
};
</script>

<style lang="scss" scoped>
</style>